import Repository from './repository'

const resource = '/totem'

export default {

  salvar (data) {
    return Repository.post(`${resource}`, data)
  },
}
