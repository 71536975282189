<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    class="d-flex justify-center"
  >
    <div
      class="d-flex justify-center align-center"
      :style="{ backgroundColor: cssSelect, height: `100%`, width: `100%` }"
    >
      <div>
        <div class="mb-12">
          <h2>A sua avaliação sobre o atendimento é super importante para o nosso desenvolvimento.</h2>
        </div>
        <v-card
          flat
          align="center"
          class="mb-12"
        >
          <vue-feedback-reaction
            v-model="feedback"
            :labels="labelClass"
          />
        </v-card>
        <!-- <v-div align="center">
          <h6>Desenvolvido por.</h6>
        </v-div> -->
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import { VueFeedbackReaction } from 'vue-feedback-reaction'

  import totemRep from '../../../components/services/totemRepository'

  export default {
    components: {
      VueFeedbackReaction,
    },
    data: () => ({
      feedback: '',
      cssSelect: '#E7E5F5',
      dialog: true,
      labelClass: ['Péssimo', 'Ruim', 'Regular', 'Bom', 'Ótimo'],
    }),
    watch: {
      feedback (value) {
        // alert(value)
        this.salvar(value)
      },
    },
    methods: {
      salvar (value) {
        this.cssSelect = this.setaCores(value)
        this.dataToten = {
          nrSatisfacao: value,
        }
        setTimeout(() => {
          // this.$refs.txtcodigo.focus()
          totemRep.salvar(this.dataToten).then(() => {
            this.$swal({
              icon: 'success',
              text: 'Foi muito bom atendê-lo(a), você é um(a) cliente especial.',
              confirmButtonText: 'Obrigado!',
            }).then((result) => {
              this.cssSelect = '#E7E5F5'
              if (result.isConfirmed) this.$router.go()
            })
          })
        }, 1000)
      },
      setaCores (nota) {
        if (nota === '1') {
          return '#5B37B7'
        } else if (nota === '2') {
          return '#BE3099'
        } else if (nota === '3') {
          return '#E39A28'
        } else if (nota === '4') {
          return '#11aa50'
        } else {
          return '#1194AA'
        }
      },
    },
  }
</script>
